import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { Container, Row, Col, Card, Modal, Button } from "react-bootstrap";
import { Helmet } from "react-helmet";
import FirstNav from "../components/Navbar";
import Footer from "../components/Footer";
import LogoSeparator from "../components/separator";
import "../assets/styles/HRSection.css";

function PMP() {
  useEffect(() => {
    AOS.init();
  }, []);

  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState({});

  const handleOpenModal = (content) => {
    setModalContent(content);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const courses = [
    {
      title: "Project Management Certified Manager (PMCM)",
      // description: "Exam and certification ",
      text1: "Project Management Manager Training program Certificate",
      image: "../assets/images/hr/hrr1.jpg",
      Price: "Price : 500$",
      Hours: "Duration : 39 Hours ",
      Sessions: "Sessions : 9 Sessions ",
      Pres: "Prerequisite: NA",
    },
    // {
    //   title: "Certified Human Resources Manager (CHRM)",
    //   // description: "Exam and certification ",
    //   text1:
    //     "Global Achievement Certified Human Resources Manager Certification",
    //   text2: "Human Resources Manager Certificate (American Academy)",
    //   text3:
    //     "Human Resources Manager Training program certificate  (Talent Track) ",
    //   image: "../assets/images/hr/hrr2.jpg",
    //   Price: "Price : 650$",
    //   Hours: "Duration : 24 Hours ",
    //   Sessions: "Sessions : 8 Sessions ",
    //   Pres: "Prerequisite: NA",
    // },
    // {
    //   title: "Human Resources Expert Certification (HREC)",
    //   // description: "Exam and certification ",
    //   text1:
    //     "Global Achievement Certified Human Resources Expert Certification",
    //   text2:
    //     "Strategic Human Resources Director Certificate (American Academy)",
    //   text3:
    //     "strategic Planning for Human Resources Training program certificate (Talent Track)   ",
    //   image: "../assets/images/hr/hrr3.jpg",
    //   Price: "Price : 650$",
    //   Hours: "Duration : 24 Hours ",
    //   Sessions: "Sessions : 8 Sessions ",
    //   Pres: "Prerequisite Option1 : CHRM",
    //   Pres2: "Prerequisite Option 2 : TDHR ,HRRS,MHRP,HRFC , CHRELL & CBMS",
    // },
    // {
    //   title: "Certification in Benefits Management and Compensation (CBMC)",
    //   // description: "Exam and certification ",
    //   text1: "	The Global Achievement Compensation Management Certification",
    //   text2:
    //     "	Certified Compensation & Benefits Management Program (American Academy) ",
    //   text3: "	Compensation Training Program Certificate (Talent Track)",
    //   image: "../assets/images/hr/hrr4.jpg",
    //   Price: "Price : 300$",
    //   Hours: "Duration : 9 Hours ",
    //   Sessions: "Sessions : 3 Sessions ",
    //   Pres: "Prerequisite: HRFC",
    // },
    // {
    //   title:
    //     "  Certificate in Human Resources: Employee Relations and Labor Laws (CHRELL)",
    //   // description: "Exam and certification ",
    //   text1: "	 Employee Relationship Management Certificate (American Academy)",
    //   text2: "	Employee Relation Training Program Certificate (Talent Track) ",
    //   text3: "",
    //   image: "../assets/images/hr/hrr5.jpg",
    //   Price: "Price : 300$",
    //   Hours: "Duration : 8 Hours ",
    //   Sessions: "Sessions : 2 Sessions ",
    //   Pres: "Prerequisite: HRFC",
    // },

    // {
    //   title: "  Human Resources Recruitment and Selection (HRRS)",
    //   // description: "Exam and certification ",
    //   text1:
    //     "	Selection and Recruitment of Human Resources Personnel Certificate (American Academy)",
    //   text2:
    //     "	The Recruitment and Selection Process program certificate (Talent Track)",
    //   text3: "",
    //   image: "../assets/images/hr/hrr6.jpg",
    //   Price: "Price : 250$",
    //   Hours: "Duration : 6 Hours ",
    //   Sessions: "Sessions : 2 Sessions ",
    //   Pres: "Prerequisite: HRFC",
    // },
    // {
    //   title: "  Training and Development of Human Resources (TDHR) ",
    //   // description: "Exam and certification ",
    //   text1:
    //     "	Training and Development for Human Resources certificate (American Academy) ",
    //   text2:
    //     "	Training and Development Training program certificate (Talent Track)",
    //   text3: "",
    //   image: "../assets/images/hr/hrr7.jpg",
    //   Price: "Price : 250$",
    //   Hours: "Duration : 6 Hours ",
    //   Sessions: "Sessions : 2 Sessions ",
    //   Pres: "Prerequisite: HRFC",
    // },
    // {
    //   title: "Management of Human Resources Performance (MHRP)",
    //   // description: "Exam and certification ",
    //   text1:
    //     "Management of Human Resources Performance Certificate (American Academy)",
    //   text2:
    //     "Performance Management Training Program Certificate (Talent Track)",
    //   text3: "",
    //   image: "../assets/images/hr/hrr8.jpg",
    //   Price: "Price : 250$",
    //   Hours: "Duration : 6 Hours ",
    //   Sessions: "Sessions : 2 Sessions ",
    //   Pres: "Prerequisite: HRFC",
    // },
  ];

  return (
    <>
      <Helmet>
        <title>Talent Track PMP Certification Programs</title>
        <meta
          name="description"
          content="Talent Track is a leading provider of online and offline HR Programs and Certifications in Dubai, Gulf countries, and globally. Our high-quality training programs aim to bridge the skills gap in the HR field and empower professionals to excel."
        />
        <meta
          name="keywords"
          content="HR certification, human resources Programs and Certifications, HR training, HR programs, HR certifications online, HR Programs and Certifications online, HR training online, HR certification Dubai, HR certification Gulf, HR certification worldwide"
        />
      </Helmet>

      <FirstNav />
      <Container className="mt-2 text-center">
        <Row>
          <Col lg={8} className="offset-lg-2" data-aos-duration="1000">
            <h1 className="mt-4 mb-4">
              Talent Track PMP Certification Programs
            </h1>
            <LogoSeparator />
            <h3 className="m-2">Online / Offline PMP Programs and Certifications</h3>
          </Col>
        </Row>
        <Row className="m-2">
          <Col lg={12}>
            <Row>
              {courses.map((course, index) => (
                <Col
                  key={index}
                  md={4}
                  className="mb-5 coursecol"
                  onClick={() => handleOpenModal(course)}
                >
                  <Card className="cardstyle">
                    <Card.Img
                      className="cardimage"
                      variant="top"
                      src={course.image}
                      alt={course.title}
                    />
                    <Card.Body>
                      <Card.Title>{course.title}</Card.Title>
                      <Card.Text>{course.description}</Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
      </Container>
      <Footer />

      {/* Modal */}
      <Modal
        className="text-black"
        show={showModal}
        onHide={handleCloseModal}
        size="lg"
      >
        <Modal.Header className="text-center" closeButton>
          <Modal.Title>{modalContent.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="text-center">
            {modalContent.description && <h2>{modalContent.description}</h2>}
          </div>
          {modalContent.text1 && <li>{modalContent.text1}</li>}
          {modalContent.text2 && <li>{modalContent.text2}</li>}
          {modalContent.text3 && <li>{modalContent.text3}</li>}
          {modalContent.Hours && (
            <h5 style={{ color: "black", textAlign: "center" }}>
              {modalContent.Hours}
            </h5>
          )}
          {modalContent.Sessions && (
            <h5 style={{ color: "black", textAlign: "center" }}>
              {modalContent.Sessions}
            </h5>
          )}
          {modalContent.Pres && (
            <h5 style={{ color: "black", textAlign: "center" }}>
              {modalContent.Pres}
            </h5>
          )}
          {modalContent.Pres2 && (
            <h5 style={{ color: "black", textAlign: "center" }}>
              {modalContent.Pres2}
            </h5>
          )}
          {modalContent.Price && (
            <h3 style={{ color: "green", textAlign: "center" }}>
              {modalContent.Price}
            </h3>
          )}

          <div className="text-center">
            <Button
              className="m-3"
              variant="outline-primary"
              onClick={handleCloseModal}
            >
              Register Course
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default PMP;
