import React, { useEffect } from "react";
import { Box } from "@chakra-ui/react";
import { Container, Col } from "react-bootstrap";
import "aos/dist/aos.css";
import AOS from "aos";
import { Helmet } from "react-helmet";
import "../assets/styles/Cards.css";

function ManagmentCourses() {
  useEffect(() => {
    AOS.init({ duration: 1500 });
  }, []);
  return (
    <section
      className="course-grid-section"
      style={{ display: "flex", minHeight: "100vh", alignItems: "center" }}
      id="Courses-section"
    >
      <Helmet>
        <meta
          name="description"
          content="Explore a diverse range of Programs and Certifications offered by Talent Track to enhance your skills and advance your career. From HR Diploma to Graphic Design, TEFL to Digital Marketing, our meticulously crafted Programs and Certifications are designed to equip you with the knowledge and expertise needed to excel in today's competitive job market. Start your journey towards personal and professional growth with Talent Track's comprehensive learning solutions. Join us today and unlock your potential!"
        />
      </Helmet>
      <Container className="text-center">
        <div>
          <h1 className="mt-2">Management Certification Programs </h1>
        </div>
        <Box className="ag-courses_box">
          <Col
            lg={4}
            md={6}
            sm={12}
            className="ag-courses_item agta"
            data-aos="fade-down"
          >
            <a href="/Programs/hr" className="ag-courses-item_link">
              <div
                className="ag-courses-item_bg"
                style={{ backgroundColor: "#4a83ff9d" }}
              ></div>
              <div className="ag-courses-item_title">
                Human Recourses Certification Programs
              </div>

              <div className="ag-courses-item_date-box">
                <p>
                  <span className="ag-courses-item_date">HRCP</span>
                </p>
              </div>
            </a>
          </Col>

          <Col
            lg={4}
            md={6}
            sm={12}
            className="ag-courses_item"
            data-aos="fade-down"
          >
            <a href="/SupplyChain" className="ag-courses-item_link">
              <div
                className="ag-courses-item_bg"
                style={{ backgroundColor: "#c935739a" }}
              ></div>

              <div className="ag-courses-item_title">
                Supply Chain Certification Programs
              </div>

              <div className="ag-courses-item_date-box">
                <p>
                  <span className="ag-courses-item_date">SCCP</span>
                </p>
              </div>
            </a>
          </Col>

          <Col
            lg={4}
            md={6}
            sm={12}
            className="ag-courses_item"
            data-aos="fade-down"
          >
            <a href="/" className="ag-courses-item_link">
              <div
                className="ag-courses-item_bg"
                style={{ backgroundColor: "#00e0c29c" }}
              ></div>

              <div className="ag-courses-item_title">
                Project Managment Certification Programs
              </div>

              <div className="ag-courses-item_date-box">
                <a
                  href="/pmp"
                >
                  <p>
                    <span className="ag-courses-item_date">PMCP</span>
                  </p>
                </a>
              </div>
            </a>
          </Col>
        </Box>
      </Container>
    </section>
  );
}

export default ManagmentCourses;
