import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { Container, Row, Col, Card, Modal, Button } from "react-bootstrap";
import { Helmet } from "react-helmet";
import FirstNav from "../components/Navbar";
import Footer from "../components/Footer";
import LogoSeparator from "../components/separator";
import "../assets/styles/HRSection.css";

function SupplyChain() {
  useEffect(() => {
    AOS.init();
  }, []);

  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState({});

  const handleOpenModal = (content) => {
    setModalContent(content);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const courses = [
    {
      title: "Certified Lean Synchronization and Quality Management Training Program Certificate ",
      image: "../assets/images/hr/hrr1.jpg",
      Price: "Price : 250$",
      Hours: "Duration : 12 Hours ",
      Sessions: "Sessions : 3 Sessions ",
      Pres: "Prerequisite: COPMP ",
    },
    {
      title: "Certified operation and process Management professional Training",
      // description: "Exam and certification ",
      image: "../assets/images/hr/hrr2.jpg",
      Price: "Price : 350$",
      Hours: "Duration : 20 Hours ",
      Sessions: "Sessions : 5 Sessions ",
      Pres: "Prerequisite: CSCM ",
    },
    {
      title: "Supply Chain Manager Training program Certificate ",
      // description: "Exam and certification ",
      image: "../assets/images/hr/hrr3.jpg",
      Price: "Price : 450$",
      Hours: "Duration : 24 Hours ",
      Sessions: "Sessions : 6 Sessions ",
      Pres2: "Prerequisite : NA",
    },
    {
      title: "Procurement system and management Training program certificate",
      image: "../assets/images/hr/hrr4.jpg",
      Price: "Price : 250$",
      Hours: "Duration : 12 Hours ",
      Sessions: "Sessions : 3 Sessions ",
      Pres: "Prerequisite: NA",
    },
    {
      title:
        "Certified contract management officer Training program certificate",
      image: "../assets/images/hr/hrr5.jpg",
      Price: "Price : 450$",
      Hours: "Duration : 16 Hours ",
      Sessions: "Sessions : 4 Sessions ",
      Pres: "Prerequisite: NA",
    },

  ];

  return (
    <>
      <Helmet>
        <title>Talent Track Supply Certification Programs</title>
      
      </Helmet>

      <FirstNav />
      <Container className="mt-2 text-center">
        {/* <Row>
          <Col lg={8} className="offset-lg-2" data-aos-duration="1000">
            <h1 className="mt-4 mb-4">
              Talent Track Supply Chain Certification Programs
            </h1>
            <p>
              Talent Track is a leading provider of online and offline Supply Chain
              courses in Dubai, Gulf countries, and globally.
            </p>
            <p>
              Our high-quality training programs aim to bridge the skills gap in
              the Supply Chain field and empower professionals to excel.
            </p>
            <h2>Mission Statement</h2>
            <p data-aos="fade-up">
              Our mission is to empower professionals in Dubai, Gulf countries
            </p>
            <p data-aos="fade-up">
              and worldwide with the necessary skills and knowledge to succeed
              in the dynamic field ofSupply Chain. We offer practical
              courses that are up-to-date with the latest Supply Chain trends and best
              practices.
            </p>
            <p data-aos="fade-up">
              We offer practical courses that are up-to-date with the latest Supply Chain
              trends and best practices.
            </p>
          </Col>
        </Row> */}
        <Row className="m-2">
            <h3 className="m-2">Online / Offline Supply Chain Programs and Certifications</h3>
          <Col lg={12}>
            <Row>
              {courses.map((course, index) => (
                <Col
                  key={index}
                  md={4}
                  className="mb-5 coursecol"
                  onClick={() => handleOpenModal(course)}
                >
                  <Card className="cardstyle">
                    <Card.Img
                      className="cardimage"
                      variant="top"
                      src={course.image}
                      alt={course.title}
                    />
                    <Card.Body>
                      <Card.Title>{course.title}</Card.Title>
                      <Card.Text>{course.description}</Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
      <LogoSeparator />
      </Container>
      <Footer />

      {/* Modal */}
      <Modal
        className="text-black"
        show={showModal}
        onHide={handleCloseModal}
        size="lg"
      >
        <Modal.Header className="text-center" closeButton>
          <Modal.Title>{modalContent.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="text-center">
            {modalContent.description && <h2>{modalContent.description}</h2>}
          </div>
          {modalContent.text1 && <li>{modalContent.text1}</li>}
          {modalContent.text2 && <li>{modalContent.text2}</li>}
          {modalContent.text3 && <li>{modalContent.text3}</li>}
          {modalContent.Hours && (
            <h5 style={{ color: "black", textAlign: "center" }}>
              {modalContent.Hours}
            </h5>
          )}
          {modalContent.Sessions && (
            <h5 style={{ color: "black", textAlign: "center" }}>
              {modalContent.Sessions}
            </h5>
          )}
          {modalContent.Pres && (
            <h5 style={{ color: "black", textAlign: "center" }}>
              {modalContent.Pres}
            </h5>
          )}
          {modalContent.Pres2 && (
            <h5 style={{ color: "black", textAlign: "center" }}>
              {modalContent.Pres2}
            </h5>
          )}
          {modalContent.Price && (
            <h3 style={{ color: "red", textAlign: "center" }}>
              {modalContent.Price}
            </h3>
          )}

          <div className="text-center">
            <Button
              className="m-3"
              variant="outline-primary"
              onClick={handleCloseModal}
            >
              Register Course
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default SupplyChain;
